
button[disabled], button[disabled]:hover {
  --tw-bg--disaled-opacity: 0.5;
  background-color: rgb(29 78 216 / var(--tw-bg--disaled-opacity));
  cursor: not-allowed;
}

header nav .current {
    --tw-text-opacity: 1;
    color: rgb(1 85 163 / var(--tw-text-opacity));
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

button.danger {
  background: #ff0055;
  border-color: #ff0055;
}

button.danger:hover,
button.danger:active {
  background: #f34343;
  border-color: #f34343;
}

/* Base styles */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  th, td {
    padding: 6px;
    font-size: 14px;
  }
}

/* Small devices (phones, 600px and down) */
@media (max-width: 1160px) {
  th, td {
    padding: 4px;
    font-size: 12px;
  }

  /* Optional: Stack cells vertically */
  table, thead:not(.stat-tables thead), tbody:not(.stat-tables tbody), th:not(.stat-tables th), td:not(.stat-tables td), tr:not(.stat-tables tr) {
    display: block;
    text-align: center;
  }

  /* Hide table headers */
  thead tr:not(thead .stattr) {
    display: none;
  }

  tr {
    margin-bottom: 10px;
  }

  td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}